
import {
    SESSION_LOGIN_START,
    SESSION_LOGIN_OK,
    SESSION_LOGIN_ERR,
    SESSION_LOGOUT,
    SESSION_RELOAD,
    SESSION_TOGGLE_LOADING,
    SESSION_TOGGLE_MSG,
    NOTIFICATION_OK,
    FETCH_NOTIFICATIONS_OK,
    VIEWED_NOTIFICATION_OK,
    FETCH_ENTITLEMENTS_OK,
    GET_USERS_OK,
    SAVE_USER_OK,
    FORGOT_PASSWORD_OK,
    SETUP_NEW_USER_OK
} from 'Shared/actionTypes';

export const initialSessionState = {
    token: '',
    issuer: '',
    issued: '',
    expires: '',
    realm: '',
    role: '',
    firstName: 'Brian',
    lastName: '',
    userid: '',
    isAdmin: false,
    loading: false,
    products: [],
    entitlements: [],
    users: [],
    loginMessage: '',
};


export function session (state = initialSessionState, action) {

    console.log('SESSION', action);

    switch(action.type) {

        case SESSION_TOGGLE_LOADING :
            return Object.assign({}, state, {
                loading: action.loading,
            });

        case SESSION_TOGGLE_MSG :
            return Object.assign({}, state, {
                error: action.msg,
            });

        case SESSION_LOGIN_START :
            return Object.assign({}, state, {
                error: '',
                loading: true,
                loginMessage: '',
            });

        case SESSION_LOGIN_OK :
            /* set session as new object, to make sure no old session info lingers ... */
            return {
                ...state,
                error: '',
                loading: false,
                token: action.token,
                notifications: [],
                users: [],
                products: action.products, 
                entitlements: action.entitlements, 
                loginMessage: '',
                ...action.decoded
            };

        case SETUP_NEW_USER_OK :

            /* set session as new object, to make sure no old session info lingers ... */
            return {
                ...state,
                error: '',
                loading: false,
                token: action.token,
                loginMessage: '',
                entitlements: [], 
                products: [],
                ...action.decoded
            };

        case SESSION_LOGIN_ERR :
            /* reset session to initial state with the error */
            return Object.assign({}, initialSessionState, {
                error: action.error,
                loading: false,
            });

        case SESSION_LOGOUT :
            console.log('SESSION_LOGOUT ');
            return initialSessionState;

        case SESSION_RELOAD :
            console.log('SESSION RELOAD');

            /* set session as new object, to make sure no old session info lingers ... */
            return {
                ...state,
                error: '',
                loading: false,
                token: action.token,
                products: [],
                entitlements: [],
                users: [],
                ...action.decoded,
            };

        case NOTIFICATION_OK :
            console.log('INCOMING NOTIFICATION INIT', action);
            return Object.assign({}, state, {
                notifications: [ action.message, ...state.notifications ]
            });

        case FETCH_ENTITLEMENTS_OK :
            return Object.assign({}, state, {
                entitlements: action.entitlements,
            });

        case FETCH_NOTIFICATIONS_OK :
            return Object.assign({}, state, {
                notifications: action.notifications
            });

        case VIEWED_NOTIFICATION_OK :
            let vnStatus = Object.assign({}, state);
            vnStatus.notifications = state.notifications.filter((n) => n.notificationid !== action.notificationid);
            return vnStatus;

        case GET_USERS_OK:
            return Object.assign({}, state, {
                users: action.users
            });

        case SAVE_USER_OK:
            let saveUserState = Object.assign({}, state);
            if (action.user.isNew) {
                saveUserState.users.push(action.user);
            } else {
                saveUserState.users = saveUserState.users.map((user) => {
                    console.log('SAVE_USER_OK -- is ' + action.user.userid + ' = ' + user.userid);
                    return (parseInt(user.userid) === parseInt(action.user.userid)) ? action.user : user;
                });
            }
            return saveUserState;

        case FORGOT_PASSWORD_OK:
            return {
                ...state, 
                loginMessage: 'Please check your email'
            };

        default :
            return state;
    }
}


