import { applyMiddleware } from 'redux'
import checker from './checker'
import logger from './logger'
import thunk from 'redux-thunk'
import api from '../api'

export default applyMiddleware(
    thunk.withExtraArgument(api),
    checker,
    logger
);