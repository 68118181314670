import React from 'react';
import {Field} from 'formik';

export const apiUrl ="http://localhost:9000/api/";

export const searchPath = "/app/search/start";

export const pubkey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAwY/gX03/PugAVIskCYRv
aZUOR3I6BJfipJcdQwneL5rdkxmySykMDgYhlgBA14sue61pXho5a9AQCswhYP8b
501azLWXYdbbML1GZCICNlC0rO24qbCIaEBwTc0jg/qzH+LWoSKDaMc8FIXz6IOS
6qOx1xr/Py/c9M/s+t45Ve9J1dl2L9yTFp2qYge5yA7bNk955EugGRiJYJ6cciGR
lXiqd2mvSCKZGsxexHA72j4TupgYcALvFMiHehqmhwGucK6zVb3XXjEQEJwAduK0
cYcof3qRgnJK45FKy60K3Yws91cUXrBC2Jkq1x29KizwGFmVTohS8bvxbHp1/guZ
9wIDAQAB
-----END PUBLIC KEY-----`;

export const FormErr = (e) => <div className="field-error">{e}</div>;

export const ObjectToOptions = (opts) => {
    return Object.keys(opts).map((key) => {
        return <option key={key} value={key}>{opts[key]}</option>;
    })
};

export const loading = () => <div className="animated fadeIn pt-1 text-center loading-overlay"><div className="sk-spinner sk-spinner-pulse dead-center"></div></div>;

export const WrappedCheckbox = (props) => {
        return (
            <Field name={props.name}>
                {({ field, form }) => (
                    <label>
                        <input
                            type="checkbox"
                            {...props}
                            checked={(field.value)}
                            onChange={() => {
                                form.setFieldValue(props.name, ! field.value);
                            }}
                        />
                        {props.label}
                    </label>
                )}
            </Field>
        )
};

export const DateFormatter = (val) => {
    if (val) {
        const event = new Date(val);
        return event.toLocaleString('en-US');
    }
    return '';
};

export const NotificationTypes = {
    email: 'Email',
    sms: 'SMS',
};

export const UsStates = {
    AL: 'Alabama',
    AK: 'Alaska',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District of Columbia',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
};
