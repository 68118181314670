/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH */
export const SESSION_LOGIN_START = 'SESSION_LOGIN_START';
export const SESSION_LOGIN_OK = 'SESSION_LOGIN_OK';
export const SESSION_LOGIN_ERR = 'SESSION_LOGIN_ERR';
export const SESSION_LOGOUT = 'SESSION_LOGOUT';
export const SESSION_RELOAD = 'SESSION_RELOAD';
export const SESSION_TOGGLE_LOADING = 'SESSION_TOGGLE_LOADING';
export const SESSION_TOGGLE_MSG = 'SESSION_TOGGLE_MSG';
export const NOTIFICATION_OK = 'NOTIFICATION_OK';
export const FETCH_NOTIFICATIONS_START = 'FETCH_NOTIFICATIONS_START';
export const FETCH_NOTIFICATIONS_OK = 'FETCH_NOTIFICATIONS_OK';
export const FETCH_NOTIFICATIONS_ERR = 'FETCH_NOTIFICATIONS_ERR';
export const VIEWED_NOTIFICATION_START = 'VIEWED_NOTIFICATION_START';
export const VIEWED_NOTIFICATION_OK = 'VIEWED_NOTIFICATION_OK';
export const VIEWED_NOTIFICATION_ERR = 'VIEWED_NOTIFICATION_ERR';
export const FORGOT_PASSWORD_START = 'FORGOT_PASSWORD_START';
export const FORGOT_PASSWORD_OK = 'FORGOT_PASSWORD_OK';
export const FORGOT_PASSWORD_ERR = 'FORGOT_PASSWORD_ERR';
export const GET_PRODUCTS_START = 'GET_PRODUCTS_START';
export const GET_PRODUCTS_OK = 'GET_PRODUCTS_OK';
export const GET_PRODUCTS_ERR = 'GET_PRODUCTS_ERR';
export const FETCH_ENTITLEMENTS_START = 'FETCH_ENTITLEMENTS_START';
export const FETCH_ENTITLEMENTS_OK = 'FETCH_ENTITLEMENTS_OK';
export const FETCH_ENTITLEMENTS_ERR = 'FETCH_ENTITLEMENTS_ERR';
export const FETCH_PRODUCTS_START = 'FETCH_PRODUCTS_START';
export const FETCH_PRODUCTS_OK = 'FETCH_PRODUCTS_OK';
export const FETCH_PRODUCTS_ERR = 'FETCH_PRODUCTS_ERR';


export const AUTHENTICATE_START = 'AUTHENTICATE_START';
export const AUTHENTICATE_OK = 'AUTHENTICATE_OK';
export const AUTHENTICATE_ERR = 'AUTHENTICATE_ERR';
export const GET_USERS_START = 'GET_USERS_START';
export const GET_USERS_OK = 'GET_USERS_OK';
export const GET_USERS_ERR = 'GET_USERS_ERR';
export const SAVE_USER_START = 'SAVE_USER_START';
export const SAVE_USER_OK = 'SAVE_USER_OK';
export const SAVE_USER_ERR = 'SAVE_USER_ERR';
export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_OK = 'RESET_PASSWORD_OK';
export const RESET_PASSWORD_ERR = 'RESET_PASSWORD_ERR';
export const SETUP_NEW_USER_START = 'SETUP_NEW_USER_START';
export const SETUP_NEW_USER_OK = 'SETUP_NEW_USER_OK';
export const SETUP_NEW_USER_ERR = 'SETUP_NEW_USER_ERR';
export const SEND_USER_INVITE_START = 'SEND_USER_INVITE_START';
export const SEND_USER_INVITE_OK = 'SEND_USER_INVITE_OK';
export const SEND_USER_INVITE_ERR = 'SEND_USER_INVITE_ERR';