var _ = require('lodash');
var sprintf = require('sprintf-js').sprintf;

export const mapOrder = (array, order, key) => {
    array.sort(function (a, b) {
        var A = a[key], B = b[key];
        if (order.indexOf(A + "") > order.indexOf(B + "")) {
            return 1;
        } else {
            return -1;
        }
    });
    return array;
};

export const formatDate = (val) => {
    if (val) {
        const event = new Date(val);
        return event.toLocaleString('en-US');
    }
    return '';
};

export const formatMoney = (input, decimals) => {
    let finalVal="";
    if (typeof decimals !== "number") {
        decimals = 2;
    }

    if(input !== undefined) {
        finalVal = Number(input).toFixed(decimals);
        finalVal = sprintf("$%s", finalVal);
    } else {
        finalVal = "$0.00";
    }
    return finalVal;
}

export const getDateWithFormat = () => {
    const today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1; //January is 0!

    var yyyy = today.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    return dd + '.' + mm + '.' + yyyy;
}

export const getCurrentTime=()=>{
    const now = new Date();
    return now.getHours() + ":" + now.getMinutes()
}

export const getISODateTime=()=>{
    const now = new Date();
    return now.toISOString();
};

export const addCommas = (nStr) => {
    nStr += "";
    var x = nStr.split(".");
    var x1 = x[0];
    var x2 = x.length > 1 ? "." + x[1] : "";
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, "$1" + "," + "$2");
    }
    return x1 + x2;
}



/* given a set of criteria, return a URL */
export const encodeCriteria = (criteria) => {
    let out = [];
    let joined = '';
    for (var key in criteria) {
        var val = criteria[key];
        switch (true) {
            case (key == 'url' || key == 'redirect' || ! key):
                break;
            case (typeof val == 'object'):
                if (! _.isEmpty(val)) {
                    console.log('encode array', {key: key, val: val});
                    joined = val.join(',');
                    out.push(key + ':' + joined.replace(/\//g, '%2F'));
                } else {
                    console.log('empty array val for key', {key: key, val: val});
                }
                break;
            case (val !== ''):
                console.log('encode scalar', {key: key, val: val });
                if (typeof val === 'string' && val.includes('/')) {
                    let matches = val.match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})/);
                    if (matches) {
                        console.log('MATCHES', matches);
                        val = matches[3] + '-' + matches[1] + '-' + matches[2];
                    }
                }
                out.push(key + ':' + val);
                break;
            default:
                console.log('encode skip', {key: key, val: val });
                break;
        }
    }
    return out.join(';');
};

/* given a search criteria string, return criteria object */
export const decodeCriteria = (criteria, defaultCriteria) => {

    /* make a copy of the criteria that we can modify */
    let newCriteria = JSON.parse(JSON.stringify(defaultCriteria));

    console.log('decode: start ', newCriteria);

    if (! criteria) {
        return newCriteria;
    }

    let pairs = criteria.split(';');
    for (var i=0; i < pairs.length; i++) {
        let pair = pairs[i];
        if (pair) {
            let [k, v] = pair.split(':');
            const isArray = (Array.isArray(newCriteria[k]));
            console.log('decode: pair ', {pair: pair, key: k, val: v});
            if (v && v.indexOf(',') > 0) {
                newCriteria[k] = v.split(',');
            } else if (v) {
                let scrubbed = (typeof v === 'string') ? v.replace(/\%2F/g, '/') : v;
                newCriteria[k] = (isArray) ? [scrubbed] : scrubbed;
            }
        }
    }
    console.log('DECODE: RETURNING', newCriteria);
    return newCriteria;
};

export const selectiveClone = (obj, fields) => {

    var out = {};
    var i, key, field;

    for (i=0; i < fields.length; i++) {
        key = fields[i];
        if (obj[key]) {
            out[key] = obj[key];
        }
    }
    return out;
};
export const DealerStatuses = {
    New: 'New',
    Active: 'Active',
    Suspended: 'Suspended',
};

export const AdminUserStatuses = {
    Active: 'Active',
    Suspended: 'Suspended',
    Inactive: 'Inactive',
};

export const formatNumber = (value, prefix, suffix, decimal) => {
    if(prefix)
        return prefix+value.toFixed(decimal);
    else if (suffix) 
        return value.toFixed(decimal)+suffix;
    else 
        return value;
}

export const capitalizeFirstLetter = (string) => {
    if(string && string !== "")
        return string.charAt(0).toUpperCase() + string.slice(1);
    else
        return "";
}
