import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import * as serviceWorker from './serviceWorker';
import {
    Provider
} from 'react-redux';
import {
    createStore
} from 'redux';
import io from 'socket.io-client';

import {
    pubkey
} from './shared/constants';
import {
    receiveNotification,
    fetchNotificationsAction,
    reloadSessionAction
} from 'Actions/session';
import reducer from './reducers';
import middleware from './middleware';

/* create our store */
const store = createStore(reducer, middleware);

/* check if a token was passed via URL and set it if so */
const params = new URLSearchParams(window.location.search);
const t = params.get('token');
if (t) {
    localStorage.setItem('token', t);
}

/* check for token and load the session if it exists */
let jwt = localStorage.getItem('token');
const encodedProducts = localStorage.getItem('products');
// let products = (encodedProducts && encodedProducts !== undefined) 
//     ? JSON.parse(localStorage.getItem('products'))
//     : [];
const products = [];
console.log('PRODUCTS: ', products);

let decoded = {};
const now = Math.floor(new Date() / 1000);
if (jwt) {

    var jwtlib = require('jsonwebtoken');

    console.log('Checking token from local storage');

    try {

        decoded = jwtlib.verify(jwt, pubkey, {
            algorithms: ['RS256']
        });
        console.log('Token decoded', {
            token: jwt,
            decoded: decoded
        });

        if (decoded.expires > now) {
            store.dispatch(reloadSessionAction(jwt, decoded, products));
        } else {
            throw 'Auth token has expired';
        }

    } catch (error) {
        console.log('STARTUP TOKEN CHECK FAILED', error);
        localStorage.setItem('token', '');
        localStorage.setItem('products', '');
        jwt = false;
        decoded = false;
    }
}

if (jwt) {
    /* create our socket connection and register callbacks 
    const socket = io.connect();
    socket.on('message', (notification) => {
        store.dispatch(receiveNotification('message', notification));
    });
    socket.on('notification', (notification) => {
        store.dispatch(receiveNotification('notify', notification));
    });
    socket.on('connect', () => socket.emit('register-admin', jwt));
    socket.on('reconnect', () => socket.emit('register-admin', jwt));
    window.socket = socket;
    */
}


ReactDOM.render( 
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();

