
import { toggleLoadingAction, toggleErrorMessageAction } from '../actions/session';

const checker = (store) => (next) => (action) => {

    console.log('MIDDLEWARE: START', action.type);

    switch (true) {
        case (action.type.match(/_START$/)):
            console.log('MIDDLEWARE: start detected ', action.type);
            store.dispatch(toggleLoadingAction(true));
            break;
        case (action.type.match(/_OK$/)):
            console.log('MIDDLEWARE: success detected ', action.type);
            store.dispatch(toggleLoadingAction(false));
            break;
        case (action.type.match(/_ERR$/)):
            console.log('MIDDLEWARE: err detected ', action.type);
            store.dispatch(toggleLoadingAction(false));
            store.dispatch(toggleErrorMessageAction(action.message));
            break;

    }
    return next(action)
};

export default checker