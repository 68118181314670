import {
    encodeCriteria,
    decodeCriteria
} from '../shared/utils';

var axios = require('axios');

var API = {
    meta: {},
    jwt: '',
};

const instance = axios.create({
    timeout: 120000,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Basic bW90b3VzZXI6QjFnIW0wbmV5'
    }
});

// Set the AUTH token for any request
instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    if (token) {
        console.log('INTERCEPTOR --> SETTING TOKEN ', token);
        Object.assign(config, {headers: {"X-Auth-Token": token}});
    }

    return config;
});

/* ********************************* */
/*      Internal Methods          */
/* ********************************* */

/*
 *  Set the JWT auth token to be sent with all requests when authenticated
 *
 *  @input String token JWT token
 */
API.setToken = (token) => {
    console.log('API: setToken');
    API.jwt = token;
};

/*
 *  Process a status 200 response
 *
 *  The server returns status 200 for the success, validation
 *  and authentication use cases.
 *
 *  @input Object Server response
 *  @input Function Callback process payload on status 200 response
 */
API.processResponse = (resp, successCallback) => {

    console.log('API PROCESS RESPONSE: ', resp.data.status);

    const msg = resp.data.message;
    const status = resp.data.status;

    switch (status) {
        case 'validation':
            return {status: status, message: msg || 'Please correct the errors indicated', errors: resp.data.errors};
        case 'unauthorized':
            return {status: status, message: msg || 'You are not authorized to perform that action.', errors: {}};
        case 'authentication':
            return {status: status, message: msg || 'Please check your credentials.', errors: {}};
        case 'network':
            return {status: status, message: msg || 'Please check network connection.', errors: {}};
        case 'system':
            return {status: status, message: msg || 'A system error occurred.  Technical support has been notified.', errors: {}};
        case 'missing':
            return {status: status, message: msg || 'A resource is missing.', errors: {}};
        case 'unsupported':
            return {status: status, message: msg || 'An unsupported method was requested.', errors: {}};
        case 'error':
            return {status: status, message: msg || 'An server error occurred.', errors: {}};
        case 'notFound':
            return {status: status, message: msg || "Sorry, this resource does not exist.", errors: {}};
        default:
            let payload = successCallback(resp.data.data);
            return {status: 'ok', message: 'Processed ok', ...payload};

    }
};

/*
 *  Process a non-200 status code response
 *
 *  The server returns standard HTTP error codes for the following use cases:
 *  401: Login required
 *  404: Resource not found
 *  405: Method not supported
 *  500: Server Error
 *
 *  Return a standardized response object with the following keys:
 *  status: authentication | missing | unsupported | server
 *  message: Message to display
 *  errors: an object of errors keyed by field e.g.
 *    { 'firstName': 'First name is required', 'lastName': 'Last name is required' }
 *
 *  @input Object Server response
 *  @input Function Callback process payload on status 200 response
 */
API.processError = (errorCode) => {

    console.log('API processError', errorCode);

    switch (errorCode) {
        case 401:
            return {status: 'authentication', message: 'Please login again.', errors: {}};
        case 404:
            return {status: 'missing', message: 'Resource not found.', errors: {}};
        case 405:
            return {status: 'unsupported', message: 'Method not supported.', errors: {}};
        case 500:
            return {status: 'server', message: 'An error occurred. Technical support has been notified.', errors: {}};
        default:
            return {status: 'network', message: 'Please check your network connection.', errors: {}};
    }

};

API.authenticate = (userid, token) => {

    const url = '/api/auth/authenticate';
    return instance.post(url, {userid, token})
        .then(response => {
            console.log('API.authenticate resp', response);

            const onSuccess = (payload) => {
                return {status: 'ok', token: payload.token};
            };

            return API.processResponse(response, onSuccess);
        })
        .catch((error) => {
            return API.processError(error);
        });
};

API.login = (credentials) => {

    const url = '/api/auth/login';
    return instance.post(url, credentials)
        .then(response => {
            console.log('API.login resp', response);

            const onSuccess = (payload) => {
                return {
                    status: 'ok',
                    token: payload.token,
                    products: payload.products,
                    entitlements: payload.entitlements,
                };
            };

            return API.processResponse(response, onSuccess);
        })
        .catch((error) => {
            return API.processError(error);
        });
};

API.logout = () => {

    const url = '/api/auth/logout';
    return instance.get(url)
        .then(response => {
            console.log('API.login resp', response);

            const onSuccess = () => {
                return {status: 'ok' };
            };

            return API.processResponse(response, onSuccess);
        })
        .catch((error) => {
            return API.processError(error);
        });
};

API.fetchEntitlements = () => {

    const url = '/api/home/entitlements';
    return instance.post(url)
        .then(response => {
            console.log('API.fetchEntitlements resp', response);

            const onSuccess = (payload) => {
                return {status: 'ok', entitlements: payload.entitlements};
            };

            return API.processResponse(response, onSuccess);
        })
        .catch((error) => {
            return API.processError(error);
        });
};

API.getUsers = () => {

    const url = '/api/home/users';
    return instance.get(url)
        .then(response => {
            console.log('API.getUsers resp', response);

            const onSuccess = (payload) => {
                return {status: 'ok', users: payload.results};
            };

            return API.processResponse(response, onSuccess);
        })
        .catch((error) => {
            return API.processError(error);
        });
};


API.saveUser = (user) => {

    const url = (user.userid)
        ? '/api/home/user/' + user.userid
        : '/api/home/user';

    return instance.post(url, user)
        .then(response => {
            console.log('API.saveUser resp', response);

            const onSuccess = (payload) => {
                return {status: 'ok', user: payload.user};
            };

            return API.processResponse(response, onSuccess);
        })
        .catch((error) => {
            return API.processError(error);
        });
};

API.forgotPassword = (email) => {

    const url = '/api/auth/forgot-password';
    return instance.post(url, {email})
        .then(response => {
            console.log('API.forgotPassword resp', response);

            const onSuccess = (payload) => {
                return {status: 'ok'};
            };

            return API.processResponse(response, onSuccess);
        })
        .catch((error) => {
            return API.processError(error);
        });
};

API.resetPassword = (values) => {

    const url = '/api/auth/reset-password';
    return instance.post(url, values)
        .then(response => {
            console.log('API.resetPassword resp', response);

            const onSuccess = (payload) => {
                return {status: 'ok', token: payload.token};
            };

            return API.processResponse(response, onSuccess);
        })
        .catch((error) => {
            return API.processError(error);
        });
};

API.setupNewUser = (values) => {

    const url = '/api/auth/new-user';
    return instance.post(url, values)
        .then(response => {
            console.log('API.setupNewUser resp', response);

            const onSuccess = (payload) => {
                return {status: 'ok', token: payload.token};
            };

            return API.processResponse(response, onSuccess);
        })
        .catch((error) => {
            return API.processError(error);
        });
};

API.sendUserInvite = (userid) => {

    const url = '/api/home/user/' + userid + '/invite';
    return instance.post(url, {userid})
        .then(response => {
            console.log('API.sendUserInvite resp', response);

            const onSuccess = (payload) => {
                return {status: 'ok', message: payload.status};
            };

            return API.processResponse(response, onSuccess);
        })
        .catch((error) => {
            return API.processError(error);
        });
};

export default API;