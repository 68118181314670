import API from '../api/index';
import history from '../shared/history';
import {pubkey} from '../shared/constants';
import {
    SESSION_LOGIN_START,
    SESSION_LOGIN_OK,
    SESSION_LOGIN_ERR,
    SESSION_LOGOUT,
    SESSION_RELOAD,
    SESSION_TOGGLE_LOADING,
    SESSION_TOGGLE_MSG,
    NOTIFICATION_OK,
    FETCH_NOTIFICATIONS_START,
    FETCH_NOTIFICATIONS_OK,
    FETCH_NOTIFICATIONS_ERR,
    VIEWED_NOTIFICATION_START,
    VIEWED_NOTIFICATION_OK,
    VIEWED_NOTIFICATION_ERR,
    FORGOT_PASSWORD_START,
    FORGOT_PASSWORD_OK,
    FORGOT_PASSWORD_ERR,
    AUTHENTICATE_START,
    AUTHENTICATE_OK,
    AUTHENTICATE_ERR,
    FETCH_ENTITLEMENTS_START,
    FETCH_ENTITLEMENTS_OK,
    FETCH_ENTITLEMENTS_ERR,
    FETCH_PRODUCTS_START,
    FETCH_PRODUCTS_OK,
    FETCH_PRODUCTS_ERR,
    GET_USERS_START,
    GET_USERS_OK,
    GET_USERS_ERR,
    SAVE_USER_START,
    SAVE_USER_OK,
    SAVE_USER_ERR,
    RESET_PASSWORD_START,
    RESET_PASSWORD_OK,
    RESET_PASSWORD_ERR,
    SETUP_NEW_USER_START,
    SETUP_NEW_USER_OK,
    SETUP_NEW_USER_ERR,
    SEND_USER_INVITE_START,
    SEND_USER_INVITE_OK,
    SEND_USER_INVITE_ERR,
} from 'Shared/actionTypes';

var jwtlib = require('jsonwebtoken');

const startLogin = () => {
    return {
        type: SESSION_LOGIN_START
    };
};


const loginSuccess = (token, products, entitlements, decoded) => {
    return {
        type: SESSION_LOGIN_OK,
        token,
        products,
        entitlements,
        decoded,
    }
};

const loginError = (status, message, errors) => {
    return {
        type: SESSION_LOGIN_ERR,
        status,
        message,
        errors
    }
};

const logoutSession = () => {
    return {
        type: SESSION_LOGOUT
    }
};

export const reloadSession = (token, decoded, resources) => {
    return {
        type: SESSION_RELOAD,
        token,
        decoded,
        resources
    };
};


const toggleLoading = (loading) => {
    return {
        type: SESSION_TOGGLE_LOADING,
        loading
    };
};


const toggleErrorMessage = (msg) => {
    return {
        type: SESSION_TOGGLE_MSG,
        msg
    };
};

/* SELECTOR */
export const getSessionAppId = () => {
    return (dispatch, getState) => {
        return getState().session.appid;
    }
};

/* called when the websocket receives a notification */
export const receiveNotification = (messageType, message) => {
    return {
        type: NOTIFICATION_OK,
        messageType,
        message
    }
};

/*
 * Authenticate the user with the API server
 *
 * @input String email
 * @input String password
 * @input Integer appid
 * @input String cb Function to call on success
 * @success Update the session with JWT token and user information
 * @error Dispatch the ApiError handler
 */
export function loginAction (credentials, successCb, errorCb) {

    return (dispatch, getState) => {

        console.log('ACTION: loginAction: START ', credentials);

        dispatch(startLogin());

        return API.login({...credentials})
            .then((payload) => {

                console.log('consumer login action: back from api.login', payload)
                if (payload.status === 'ok') {

                    /* save our new jwt token for future requests */
                    API.setToken(payload.token);

                    let decoded = jwtlib.verify(payload.token, pubkey, {algorithms: ['RS256']});
                    // let decoded = jwtlib.verify(payload.token, pubkey, {algorithms: ['HS256']});

                    console.log('DECODED JWT', decoded);
                    localStorage.setItem('token', payload.token);
                    localStorage.setItem('products', JSON.stringify(payload.products));

                    /* fire off our success message */
                    dispatch(loginSuccess(payload.token, payload.products, payload.entitlements, decoded));

                    /* call the success callback */
                    successCb && successCb();

                } else {

                    dispatch(loginError(payload.status, payload.message, payload.errors));
                    errorCb && errorCb(payload.message);
                }
            });
    }
}

const forgotPasswordStart = () => {
    return {
        type: FORGOT_PASSWORD_START
    };
};


const forgotPasswordSuccess = (message) => {
    return {
        type: FORGOT_PASSWORD_OK,
        message
    }
};

const forgotPasswordError = (status, message, errors) => {
    return {
        type: FORGOT_PASSWORD_ERR,
        status,
        message,
        errors
    }
};
/*
 * Send temporary password to the user
 *
 */
export function forgotPasswordAction (username, successCb, errorCb) {

    return (dispatch, getState) => {

        console.log('ACTION: forgotPasswordAction: START ', username);

        dispatch(forgotPasswordStart());

        return API.forgotPassword(username)
            .then((payload) => {

                console.log('consumer login action: back from api.forgotPassword', payload)
                if (payload.status === 'ok') {

                    /* fire off our success message */
                    dispatch(forgotPasswordSuccess(payload.message));

                    /* call the success callback */
                    successCb && successCb();

                } else {

                    dispatch(forgotPasswordError(payload.status, payload.message, payload.errors));
                    errorCb && errorCb(payload.message);
                }
            });
    }
}

export function addNotificationAction (notification) {
    return (dispatch) => {
        console.log('ACTION: addNotificationAction', notification);
        dispatch({type: NOTIFICATION_OK, notification});
    }
}

/*
 * Authenticate the user with the API server
 *
 * @input String username
 * @input String password
 * @input String cb Function to call on success
 * @success Update the session with JWT token and user information
 * @error Dispatch the ApiError handler
 */
export function logoutAction (redirectToLoginCb) {

    console.log('logoutAction: ');

    return (dispatch) => {

        localStorage.setItem('token', '');
        dispatch(logoutSession());

        return API.logout()
            .then(() => {
                console.log('Called API.logout OK')
            })
            .catch((error) => {
                console.log('API.logout: Error ', error);

            });
    }
}

/*
 * Authenticate a dealer with the API server
 *
 * @input String email
 * @input String password
 * @input String cb Function to call on success
 * @success Update the session with JWT token and user information
 * @error Dispatch the ApiError handler
 */
export function dealerLoginAction (credentials, successCb, errorCb) {

    return (dispatch, getState) => {

        console.log('ACTION: dealerLoginAction: START ', credentials);

        dispatch(startLogin());

        return API.dealerLogin(credentials)
            .then((payload) => {

                console.log('dealer login action: back from api.dealerLogin', payload);

                if (payload.status === 'ok') {

                    let decoded = jwtlib.verify(payload.token, pubkey, {algorithms: ['RS256']});

                    console.log('DECODED JWT', decoded);
                    localStorage.setItem('token', payload.token);

                    /* fire off our success message */
                    dispatch(loginSuccess(payload.token, decoded));

                    /* call the success callback */
                    successCb();

                } else {

                    dispatch(loginError(payload.status, payload.message, payload.errors));
                    errorCb && errorCb();
                }
            });
    }
}


/*
 * Reload saved session information from local storage
 *
 * This function hydrates the session slice with information
 * pulled from local storage.
 *
 * @input Object Session info
 * @action Dispatch the reloadSession handler
 */
export function reloadSessionAction (token, decoded, products) {

    return (dispatch) => {

        dispatch(reloadSession(token, decoded, products));

    }
}

/*
 * Set the visibility of the loading spinner
 *
 * This function turns the spinner on and off
 *
 * @input Boolean Loading display value
 * @action Dispatch the toggleLoading handler
 */
export function toggleLoadingAction (loading) {
    return (dispatch) => {
        dispatch(toggleLoading(loading));
    }
}

/*
 * Set the visibility of the loading spinner
 *
 * This function turns the spinner on and off
 *
 * @input Boolean Loading display value
 * @action Dispatch the toggleLoading handler
 */
export function toggleErrorMessageAction (msg='') {
    return (dispatch) => {
        dispatch(toggleErrorMessage(msg));
    }
}

const fetchNotificationsStart = () => ({
    type: FETCH_NOTIFICATIONS_START
});
const fetchNotificationsOk = (notifications) => ({
    type: FETCH_NOTIFICATIONS_OK,
    notifications
});
const fetchNotificationsErr = (error) => ({
    type: FETCH_NOTIFICATIONS_ERR,
    error
});

export function fetchNotificationsAction (successCb, errorCb) {

    return (dispatch) => {

        console.log('ACTION: fetchNotificationsAction: START ');

        dispatch(fetchNotificationsStart());

        return API.fetchNotifications()
            .then((payload) => {
                console.log('fetchNotificationsAction: back from API.fetchNotifications', payload);
                if (payload.status === 'ok') {

                    /* fire off our success quote */
                    dispatch(fetchNotificationsOk(payload.notifications));

                    /* call the success callback (if it exists) */
                    successCb && successCb();

                } else {

                    dispatch(fetchNotificationsErr(payload.errors));
                    errorCb && errorCb(payload.errors);
                }
            });
    }
};


const viewedNotificationStart = () => ({
    type: VIEWED_NOTIFICATION_START
});
const viewedNotificationOk = (notificationid) => ({
    type: VIEWED_NOTIFICATION_OK,
    notificationid
});
const viewedNotificationErr = (error) => ({
    type: VIEWED_NOTIFICATION_ERR,
    error
});

export function viewedNotificationAction (notificationid, successCb, errorCb) {

    return (dispatch) => {

        console.log('ACTION: viewedNotificationAction: START ');

        dispatch(viewedNotificationStart());

        return API.viewedNotification(notificationid)
            .then((payload) => {
                console.log('viewedNotificationAction: back from API.viewedNotification', payload);
                if (payload.status === 'ok') {

                    /* fire off our success quote */
                    dispatch(viewedNotificationOk(notificationid));

                    /* call the success callback (if it exists) */
                    successCb && successCb();

                } else {

                    dispatch(viewedNotificationErr(payload.errors));
                    errorCb && errorCb(payload.errors);
                }
            });
    }
};




const VERIFY_TRANSFER_START = 'VERIFY_TRANSFER_START';
const verifyTransferStart = () => ({
    type: VERIFY_TRANSFER_START
});
const VERIFY_TRANSFER_OK = 'VERIFY_TRANSFER_OK';
const verifyTransferOk = (notificationid) => ({
    type: VERIFY_TRANSFER_OK,
    notificationid
});
const VERIFY_TRANSFER_ERR = 'VERIFY_TRANSFER_ERR';
const verifyTransferErr = (error) => ({
    type: VERIFY_TRANSFER_ERR,
    error
});

export function verifyTransferAction (userid, token, successCb, errorCb) {

    return (dispatch) => {

        console.log('ACTION: verifyTransferAction: START ');

        dispatch(verifyTransferStart());

        return API.verifyTransfer(userid, token)
            .then((payload) => {
                console.log('verifyTransferAction: back from API.verifyTransfer', payload);
                if (payload.status === 'ok') {

                    /* save our new jwt token for future requests */
                    API.setToken(payload.token);

                    let decoded = jwtlib.verify(payload.token, pubkey, {algorithms: ['RS256']});
                    // let decoded = jwtlib.verify(payload.token, pubkey, {algorithms: ['HS256']});

                    console.log('DECODED JWT', decoded);
                    localStorage.setItem('token', payload.token);
                    localStorage.setItem('resources', payload.resources);

                    /* fire off our success message */
                    dispatch(reloadSession(payload.token, decoded, payload.resources));

                    /* call the success callback */
                    successCb && successCb();
                } else {

                    dispatch(verifyTransferErr(payload.errors));
                    errorCb && errorCb(payload.errors);
                }
            });
    }
};


/* non-thunks */
export function createConsumerSessionAction(consumerid, successCb, errorCb) {

        return API.createConsumerSession(consumerid)
            .then((payload) => {
                console.log('createConsumerSessionAction: back from API.createConsumerSession', payload);
                if (payload.status === 'ok') {

                    /* call the success callback (if it exists) */
                    successCb && successCb(payload.token);

                } else {
                    errorCb && errorCb(payload.errors);
                }
            });
};
export function createDealerSessionAction(userid, successCb, errorCb) {

    return API.createDealerSession(userid)
        .then((payload) => {
            console.log('createDealerSessionAction: back from API.createDealerSession', payload);
            if (payload.status === 'ok') {

                /* call the success callback (if it exists) */
                successCb && successCb(payload.token);

            } else {
                errorCb && errorCb(payload.errors);
            }
        });
};
export function createDealerMotoleaseSessionAction(userid, successCb, errorCb) {

    return API.createDealerMotoleaseSession(userid)
        .then((payload) => {
            console.log('createDealerMotoleaseSession: back from API.createDealerSession', payload);
            if (payload.status === 'ok') {

                /* call the success callback (if it exists) */
                successCb && successCb(payload.url);

            } else {
                errorCb && errorCb(payload.errors);
            }
        });
};
export function sendToAdminV1Action(successCb, errorCb) {

    return API.createAdminV1Session()
        .then((payload) => {
            console.log('sendToAdminV1Action: back from API.createAdminV1Session', payload);
            if (payload.status === 'ok') {

                /* call the success callback (if it exists) */
                successCb && successCb(payload.url);

            } else {
                errorCb && errorCb(payload.errors);
            }
        });
};

/**
 * Given a resource or a comma-separated list of resources,
 * determine if the user is authorized
 */
export function isAuthorized(resource) {
    return (dispatch, getState) => {
        const {session} = getState();
        const allowed = resource.split(',');
        let ok = false;
        allowed.forEach((r) => {
            if (session.authorized.includes(r)) {
                ok = true;
            }
        });
        return ok;
    }
};

const authenticateStart = () => {
    return {
        type: AUTHENTICATE_START
    };
};
const authenticateOk = (token) => {
    return {
        type: AUTHENTICATE_OK,
        token
    };
};
const authenticateErr = (status, message, errors) => {
    return {
        type: AUTHENTICATE_ERR,
        status,
        errors,
        message
    };
};

export function authenticateAction(userid, token, successCb, errorCb) {

    return (dispatch) => {

        console.log('ACTION: authenticateAction: START ');

        dispatch(authenticateStart());

        return API.authenticate(userid, token)
            .then((payload) => {
                console.log('authenticateAction: back from API.authenticate', payload);

                if (payload.status === 'ok') {

                    /* save our new jwt token for future requests */
                    API.setToken(payload.token);

                    console.log('LOGIN', {token: payload.token, pubkey});

                    const decoded = jwtlib.verify(payload.token, pubkey, {algorithms: ['RS256']});
                    // let decoded = jwtlib.verify(payload.token, pubkey, {algorithms: ['HS256']});

                    console.log('DECODED JWT', decoded);
                    localStorage.setItem('token', payload.token);

                    /* fire off our success message */
                    dispatch(loginSuccess(payload.token, decoded, payload.notifications));

                    /* call the success callback */
                    successCb && successCb();

                } else {

                    dispatch(authenticateErr(payload.status, payload.message, payload.errors));
                    errorCb && errorCb(payload.message, payload.errors);

                }
            });
    }
}

const fetchEntitlementsStart = () => {
    return {
        type: FETCH_ENTITLEMENTS_START
    };
};
const fetchEntitlementsOk = (entitlements) => {
    return {
        type: FETCH_ENTITLEMENTS_OK,
        entitlements
    };
};
const fetchEntitlementsErr = (status, message, errors) => {
    return {
        type: FETCH_ENTITLEMENTS_ERR,
        status,
        errors,
        message
    };
};

export function fetchEntitlementsAction(successCb, errorCb) {

    return (dispatch) => {

        console.log('ACTION: fetchEntitlementsAction: START ');

        dispatch(fetchEntitlementsStart());

        return API.fetchEntitlements()
            .then((payload) => {
                console.log('fetchEntitlements: back from API.fetchEntitlements', payload);
                if (payload.status === 'ok') {

                    /* fire off our success quote */
                    dispatch(fetchEntitlementsOk(payload.entitlements));

                    /* call the success callback (if it exists) */
                    successCb && successCb();

                } else {

                    dispatch(fetchEntitlementsErr(payload.status, payload.message, payload.errors));
                    errorCb && errorCb(payload.message, payload.errors);
                }
            });
    }
}


const fetchProductsStart = () => {
    return {
        type: FETCH_PRODUCTS_START
    };
};
const fetchProductsOk = (products) => {
    return {
        type: FETCH_PRODUCTS_OK,
        products
    };
};
const fetchProductsErr = (status, message, errors) => {
    return {
        type: FETCH_PRODUCTS_ERR,
        status,
        errors,
        message
    };
};

export function fetchProductsAction(successCb, errorCb) {

    return (dispatch) => {

        console.log('ACTION: fetchProductsAction: START ');

        dispatch(fetchProductsStart());

        return API.fetchProducts()
            .then((payload) => {
                console.log('fetchProducts: back from API.fetchProducts', payload);
                if (payload.status === 'ok') {

                    /* fire off our success quote */
                    dispatch(fetchProductsOk(payload.products));

                    /* call the success callback (if it exists) */
                    successCb && successCb();

                } else {

                    dispatch(fetchProductsErr(payload.status, payload.message, payload.errors));
                    errorCb && errorCb(payload.message, payload.errors);
                }
            });
    }
}

const getUsersStart = () => {
    return {
        type: GET_USERS_START
    };
};
const getUsersOk = (users) => {
    return {
        type: GET_USERS_OK,
        users
    };
};
const getUsersErr = (error) => {
    return {
        type: GET_USERS_ERR,
        error
    };
};

export function getUsersAction(successCb, errorCb) {

    return (dispatch) => {

        console.log('ACTION: getUsersAction: START ');

        dispatch(getUsersStart());

        return API.getUsers()
            .then((payload) => {
                console.log('getUsersAction: back from API.getUsers', payload);
                if (payload.status === 'ok') {

                    /* fire off our success message */
                    dispatch(getUsersOk(payload.users));

                    /* call the success callback (if it exists) */
                    successCb && successCb();

                } else {

                    dispatch(getUsersErr(payload.error));
                    errorCb && errorCb(payload.error);
                }
            });
    }
}

const saveUserStart = () => {
    return {
        type: SAVE_USER_START
    };
};
const saveUserOk = (user) => {
    return {
        type: SAVE_USER_OK,
        user
    };
};
const saveUserErr = (error) => {
    return {
        type: SAVE_USER_ERR,
        error
    };
};

export function saveUserAction(user, successCb, errorCb) {

    return (dispatch) => {

        console.log('ACTION: saveUserAction: START ', {user});

        const isNew = (user.userid > 0) ? false : true;

        dispatch(saveUserStart());

        return API.saveUser(user)
            .then((payload) => {
                console.log('saveUserAction: back from API.saveUser', payload);
                if (payload.status === 'ok') {

                    /* fire off our success message */
                    dispatch(saveUserOk({...payload.user, isNew}));

                    /* call the success callback (if it exists) */
                    successCb && successCb();

                } else {

                    dispatch(saveUserErr(payload.error));
                    errorCb && errorCb(payload.error);
                }
            });
    }
}



const resetPasswordStart = () => {
    return {
        type: RESET_PASSWORD_START
    };
};
const resetPasswordOk = (token) => {
    return {
        type: RESET_PASSWORD_OK,
        token
    };
};
const resetPasswordErr = (error) => {
    return {
        type: RESET_PASSWORD_ERR,
        error
    };
};

export function resetPasswordAction(userid, code, password, confirmation, successCb, errorCb) {

    return (dispatch) => {

        console.log('ACTION: resetPasswordAction: START ');

        dispatch(resetPasswordStart());

        return API.resetPassword(userid, code, password, confirmation)
            .then((payload) => {
                console.log('resetPasswordAction: back from API.resetPassword', payload);
                if (payload.status === 'ok') {

                    /* fire off our success message */
                    dispatch(resetPasswordOk(payload.token));

                    /* call the success callback (if it exists) */
                    successCb && successCb();

                } else {

                    dispatch(resetPasswordErr(payload.error));
                    errorCb && errorCb(payload.error);
                }
            });
    }
}

const setupNewUserStart = () => {
    return {
        type: SETUP_NEW_USER_START
    };
};
const setupNewUserOk = (token, decoded) => {
    return {
        type: SETUP_NEW_USER_OK,
        token,
        decoded
    };
};
const setupNewUserErr = (error) => {
    return {
        type: SETUP_NEW_USER_ERR,
        error
    };
};

export function setupNewUserAction(values, successCb, errorCb) {

    return (dispatch) => {

        console.log('ACTION: setupNewUserAction: START ');

        dispatch(setupNewUserStart());

        return API.setupNewUser(values)
            .then((payload) => {
                console.log('setupNewUserAction: back from API.setupNewUser', payload);
                if (payload.status === 'ok') {

                    /* save our new jwt token for future requests */
                    API.setToken(payload.token);

                    let decoded = jwtlib.verify(payload.token, pubkey, {algorithms: ['RS256']});
                    // let decoded = jwtlib.verify(payload.token, pubkey, {algorithms: ['HS256']});

                    console.log('DECODED JWT', decoded);
                    localStorage.setItem('token', payload.token);
                    localStorage.setItem('products', JSON.stringify(payload.products));

                    /* fire off our success message */
                    dispatch(setupNewUserOk(payload.token, decoded));

                    /* call the success callback */
                    successCb && successCb();

                } else {

                    dispatch(setupNewUserErr(payload.status, payload.message, payload.errors));
                    errorCb && errorCb(payload.message);
                }
            });
    }
}

const sendUserInviteStart = () => {
    return {
        type: SEND_USER_INVITE_START 
    };
};
const sendUserInviteOk = (status) => {
    return {
        type: SEND_USER_INVITE_OK,
        status
    };
};
const sendUserInviteErr = (error) => {
    return {
        type: SEND_USER_INVITE_ERR,
        error
    };
};

export function sendUserInviteAction(userid, successCb, errorCb) {

    return (dispatch) => {

        console.log('ACTION: sendUserInviteAction: START ');

        dispatch(sendUserInviteStart());

        return API.sendUserInvite(userid)
            .then((payload) => {
                console.log('sendUserInviteAction: back from API.sendUserInvite', payload);
                if (payload.status === 'ok') {

                    /* fire off our success message */
                    dispatch(sendUserInviteOk(payload.status));

                    /* call the success callback (if it exists) */
                    successCb && successCb();

                } else {

                    dispatch(sendUserInviteErr(payload.error));
                    errorCb && errorCb(payload.error);
                }
            });
    }
}