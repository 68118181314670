
import {
GET_PRODUCTS_OK,
} from 'Shared/actionTypes';

export const initialProductsState = {
    productsById: {
        '10000': {
            sku: 'ABC1', 
            title: 'Anger Management', 
            shortDesc: 'Learn how to handle that anger!', 
            description: 'Do you have anger problems?  This video ...',
            boxart: '/assets/boxart/ABC1/cover.jpg',
            runtime: 48,
        },
    },
    loading: false,
};


export function products (state = initialProductsState, action) {

    switch(action.type) {

        case GET_PRODUCTS_OK :
            return {
                ...state,
                productsById: {
                    ...state.productsById,
                    ...action.products,
                },
            };

        default :
            return state;
    }
}


