import { combineReducers } from 'redux'
import {reducer as toastrReducer} from 'react-redux-toastr';

import { session } from './session';
import { products } from './products';


export default combineReducers({
    products: products,
    session: session,
    toastr: toastrReducer,
});